import { graphql } from 'gatsby'
import React from 'react'
import ProcessSections from '../../utils/processSections'

export default function MissionStatement({ data }) {
  const sections = data.pageContent.nodes[0]

  return (
    <div className="content-width">
      <ProcessSections sections={sections} />
    </div>
  )
}

export const PageContent = graphql`
  query HistoryContent {
    pageContent: allSanityPageContent(
      filter: { location: { eq: "/history" } }
    ) {
      nodes {
        id
        title
        displayTitle
        content: _rawContent
      }
    }
  }
`
